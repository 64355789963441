body {
  background-attachment: fixed;
  background-image: url('../src/assets/bg-mobile.jpg');
  background-size: 100% 100%;
  background-position: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.desktop {
  display: none;
}

.mobile {
  display: inherit;
}

@media only screen and (min-width: 480px){
  body {
    background-image: url('../src/assets/bg-desktop.jpg');
    background-size: cover;
  }

  .desktop {
    display: inherit;
  }

  .mobile {
    display: none;
  }
};
